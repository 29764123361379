import * as React from 'react';
import styled from 'styled-components';
import { useUiStore } from '../../stores/ui';
import { observer } from 'mobx-react';
import images from '../../constants/images';
import colors from '../../constants/colors';
import sexpressClient from '../../utils/sexpress';
import toast from 'react-hot-toast';
import i18n from '../../constants/i18n';
import { PaymentMethod } from '../../shared';
import GiglinkedButton from '../common/GiglinkedButton';
import { TextH4 } from '../common/Typography';
import { UseUserStore } from '../../stores/user';

const RemoveMethodModal = observer(
  ({
    setPaymentInfo,
  }: {
    setPaymentInfo: (paymentInfo: PaymentMethod | null) => void;
  }) => {
    const uiStore = useUiStore();
    const user = UseUserStore();
    const me = user.getMe();
    const isArtist = me?.artist;

    const handleRemovePaymentMethod = async () => {
      try {
        await sexpressClient.detachPaymentMethod(); // TODO: handle the artist case here
        setPaymentInfo(null);
        toast.success(
          isArtist
            ? i18n(
                'SettingsScreen.accountRemoved',
                'You successfully removed a bank account',
              )
            : i18n(
                'SettingsScreen.cardRemoved',
                'You successfully removed a card',
              ),
        );
        uiStore.closePopup();
      } catch (e) {
        const err = e as Error;
        toast.error(err.message);
      }
    };
    return (
      <Main>
        <Close src={images.closeIconBlack} onClick={uiStore.closePopup} />
        <TextH4 style={{ color: colors.black }}>
          {i18n(
            'RemoveMethodModal.title',
            'Are you sure you want to delete your payment method?',
          )}
        </TextH4>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}
        >
          <GiglinkedButton
            label={i18n('RemoveMethodModal.yesDelete', 'Yes, delete')}
            onClick={handleRemovePaymentMethod}
            style={{ border: 'none' }}
            warning
          />
          <GiglinkedButton
            label={i18n('RemoveMethodModal.noTakeBack', 'No, take me back')}
            onClick={() => {
              uiStore.closePopup();
            }}
          />
        </div>
      </Main>
    );
  },
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 4rem;
  max-width: 627px;
  height: 338px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  text-align: center;
  border: 1px solid #a59cee;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 600px;
  }
`;

const Close = styled.img`
  cursor: pointer;
  margin-left: auto;
  object-fit: contain;
  height: 12px;
`;

export default RemoveMethodModal;
