// eslint-disable-next-line
const dev = {
  mediaBaseUrl: 'https://d3ls406vnrgcfa.cloudfront.net',
  // apiURL: 'http://localhost:1337',
  apiURL: 'https://devapi.giglinked.live',
  env: 'dev',
  stripePublishableKey:
    'pk_test_51KH8GDA6AH7EjbNgxTFb4KPuN5JUaS3QkJKSmY6S50NceWQEddJNgortayCFQDhvkBJ6h2onNOxBDIsIZmZjOe2300yhpTF1o6',
  googleClientId:
    '781089453821-prelcvbea6e17rb88fr27qcjt57ouu9v.apps.googleusercontent.com',
  amplitudeAPIKey: '8599f060c9c2a561028875e640c3b09c',
  mapboxAccessToken:
    'pk.eyJ1Ijoia3NlbmlhLWdpZ2xpbmtlZCIsImEiOiJjbGxsZHR0a24yNWhwM3NuZ203eXlxajFjIn0.co1J-kscgkqFOzOIrrFecg',
};
// eslint-disable-next-line
const prod = {
  apiURL: 'https://api.giglinked.live',
  env: 'prod',
  googleClientId: dev.googleClientId,
  mediaBaseUrl: dev.mediaBaseUrl,
  stripePublishableKey:
    'pk_live_51KH8GDA6AH7EjbNgjBW5VbOvvSHRJ2Kv9YrAcRcVniwX1vX5mNwXHf8pmRWkVoQLJyGucS92GMLjjjvmgxNcHuR9006KdmmnOK',
  amplitudeAPIKey: 'a49b0c7d34e4dd6ce757e0f8788d7f27',
  mapboxAccessToken: dev.mapboxAccessToken,
};

const config = dev;

export default config;
