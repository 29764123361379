import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import i18n from '../../../../../../constants/i18n';
import { InputLabel } from '../../../../../common/Typography';
import GiglinkedTextInput from '../../../../../common/GiglinkedTextInput';
import GiglinkedLocationAutocomplete from '../../../../../common/GiglinkedLocationAutocomplete';
import GiAuthContainer from '../../../../../common/GiAuthContainer';
import { ProgressBar } from 'react-bootstrap';
import GiglinkedButton from '../../../../../common/GiglinkedButton';

interface GroupProps {
  location: string;
  setActName: (str: string) => void;
  setLocation: (location: string) => void;
  setFirstName: (str: string) => void;
  setLastName: (str: string) => void;
  progress: number;
  next: () => void;
}

const StepperRegistrationGroupAbout = observer(
  ({
    location,
    setActName,
    setLocation,
    setFirstName,
    setLastName,
    progress,
    next,
  }: GroupProps) => {
    return (
      <GiAuthContainer
        title={i18n('StepperRegistrationGroupAbout.title', 'About your group')}
        secondaryText={i18n(
          'StepperRegistrationGroupAbout.description',
          'If your group does not have a stage name, you can enter your name followed by "band" or "duo"',
        )}
        editableContent={
          <>
            <MainBox>
              <InputLabel htmlFor="firstName">
                {i18n('RegistrationController.firstName', 'First name')}
              </InputLabel>
              <GiglinkedTextInput
                type="text"
                id="first-name"
                onChangeText={setFirstName}
              />
              <InputLabel htmlFor="lastName">
                {i18n('RegistrationController.lastName', 'Last name')}
              </InputLabel>
              <GiglinkedTextInput
                type="text"
                id="last-name"
                onChangeText={setLastName}
              />
              <InputLabel htmlFor="actName">
                {i18n(
                  'StepperRegistrationGroupAbout.groupStageName',
                  'Stage name',
                )}
              </InputLabel>
              <GiglinkedTextInput
                type="text"
                id="actName"
                onChangeText={setActName}
              />

              <InputLabel htmlFor="cityState">
                {i18n('StepperRegistrationGroupAbout.city', 'City')}
              </InputLabel>
              <GiglinkedLocationAutocomplete
                type="text"
                id="location"
                location={location}
                setLocation={setLocation}
              />
            </MainBox>

            <BoxBottom>
              <ProgressBar
                animated
                striped
                style={{ width: '100%' }}
                min={0}
                max={6}
                now={progress}
                variant="success"
              />

              <GiglinkedButton
                style={{
                  border: 'none',
                }}
                label={i18n('RegistrationStepper.next', 'Next')}
                onClick={next}
              />
            </BoxBottom>
          </>
        }
      />
    );
  },
);
const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const BoxBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 10%;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: 5px;
    margin-top: 0;
  }
`;

export default StepperRegistrationGroupAbout;
